<template>
	<form
		v-if="isEmpty"
		ref="fileform"
		class="attach-file"
		@drop="onDrop"
	>
		<div class="attach-file__wrapper">
			<div>
				<BaseSvgIcon
					name="file"
					class="attach-file__icon"
				/>
			</div>
			<p class="attach-file__title">Перетащите сюда файл или</p>
			<BaseButton
				class="attach-file__button"
				type="button"
				view="border"
				role="label"
			>
				<input type="file" hidden :multiple="isMulti" @change="onAddFile">
				Выберите файл
			</BaseButton>
		</div>
	</form>

	<BaseButton
		v-else
		class="file-info"
	>
        <div v-if="!isMulti">
            <span class="file-info__title">{{ file.name }}</span>
            <BaseIcon
                icon="icon-delete"
                class="file-info__icon"
                @click.native="onClear"
            />
        </div>
        <div v-else>
            <div 
                v-for="(name, nameKey) in filesName"
                :key="nameKey"
            >
                <div class="file-info__content_multi">
                    <span class="file-info__title" :class="{'file-info__title_multi': isMulti}">{{ name }}</span>
                    <BaseIcon
                        icon="icon-delete"
                        class="file-info__icon"
                        @click.native="onClear(name)"
                    />
                </div>
            </div>
        </div>
	</BaseButton>
</template>

<script>
import BaseSvgIcon from "@/components/Base/BaseSvgIcon"
import BaseIcon from "@/components/Base/BaseIcon"
import BaseButton from "@/components/Base/BaseButton"

export default {
	name: "AttachFile",
	components: {
		BaseSvgIcon,
		BaseButton,
		BaseIcon
	},
	props: {
		file: {
			type: File,
			default: null
		},
        files: {
            type: Array,
            default: () => ([])
        },
        isMulti: {
            type: Boolean,
            default: false
        }
	},
    computed: {
        filesName() {
            const filesName = this.files.map(file => {
                const {name = ''} = file;
                return String(name);
            });
            return filesName;
        },
        isEmpty() {
            const isEmpty = this.isMulti ? this.files.length === 0 : this.file === null;
            return isEmpty;
        }
    },
	// data() {
	// 	return {
	// 		file: this.fileData
	// 	}
	// },
	mounted() {
		this.resetEvents()
	},
	methods: {
		onClear(name = '') {
			// this.file = null
			this.$emit('clear', name);
		},
		/**
		 * Сработает при нажатии на кнопку
		 */
		onAddFile(event) {
			// this.file = event.target.files[0]
            if (!this.isMulti)
                this.$emit('change', event.target.files[0]);
            else
                this.onAddFiles(event);
		},
        onAddFiles(event) {
            const {target = {}} = event;
            const {files = []} = target;
            this.$emit('change', files);
        },
		/**
		 * Сработает при перетаскивании
		 */
		onDrop(event) {
			// this.file = event.dataTransfer.files[0]
            if (!this.isMulti)
                this.$emit('change', event.dataTransfer.files[0])
            else
                this.onDropFiles(event);
		},
        onDropFiles(event) {
            const {dataTransfer = {}} = event;
            const {files = []} = dataTransfer;
            this.$emit('change', files);
        },
		/**
		 * Сбрасывает действия по умолчанию
		 */
		resetEvents() {
			if (!this.$refs.fileform) {return}

			const events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
			events.forEach( function( evt ) {
				this.$refs.fileform.addEventListener(evt, function(e){
					e.preventDefault();
					e.stopPropagation();
				}.bind(this), false);
			}.bind(this));
		}
	},
	// watch: {
	// 	file() {
	// 		this.$emit('change', this.file)
	// 	}
	// }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.attach-file {
	// width: 465px;
	height: 276px;
	border: 3px dashed #DCE1E8;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__icon {
		display: block;
		width: 30px;
	}
	&__title {
		margin: 18px;
		width: 134px;
		text-align: center;
	}
	&__button {
		border-radius: 1000px;
	}

	.btn-wrapper {
		position: relative;

		.fake-file {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			cursor: pointer;

			&__input {
				display: none;
			}
		}
	}

}

.file-info {
	// background: $primary-color;
	// height: 36px;
	// width: 100%;
	// border-radius: 4px;
	// display: flex;
	// align-items: center;
	// padding-left: ;
	max-width: 100%;
	width: 100%;
	display: flex;
	text-align: left;

	&__title {
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__icon {
		font-size: 12px;
		flex-shrink: 0;
		margin-left: 10px;
	}
}
</style>