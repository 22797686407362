// import SchemeLayoutReconciliationDownload from '@/schemes/SchemeLayoutReconciliationDownload';
// const {reconciliations} = SchemeLayoutReconciliationDownload;
import ServiceApi from '../ServiceApi';
/** Сервис загрузки сверок */
export default class ServiceReconciliationDownload {
    /**
     * Конструктор
     * @param {Number} maxSizeFile - максимальный размер для файла
     */
    constructor(maxSizeFile = 0) {
        this.maxSizeFile = parseFloat(maxSizeFile);
    }
    /**
     * Загрузить сверки
     * @static
     * @async
     * @param {Array} reconciliations - сверки
     * @param {Function} actionAfter - событие сработающее после загрузки
     */
    static async downloadReconciliations(reconciliations = [], actionAfter = () => {}) {
        const requestPackage = reconciliations.map(reconciliation => this.downloadReconciliation(reconciliation, 1));
        try{
            await Promise.all(requestPackage);
            actionAfter();
        }
        catch(error) {
            console.log(error);
        }
    }
    /**
     * Загрузить сверку
     * @static
     * @async
     * @param {Object} reconciliation - объект сверки
     * @param {Number} step - номер шага загрузки
     * @returns {Boolean}
     */
    static async downloadReconciliation(reconciliation = {}, step = -1) {
        const {fileId = '-1', presetId = '-1', date = '', type = 1, steps = 0} = reconciliation;
        if (step <= steps && step !== -1) {
            const postData = {file_id: fileId, preset_id: presetId, date, step, type, is_loading: true};
            try {
                await ServiceApi.post('reconciliation', '/upload/default/add-to-revise/', postData);
                const nextStep = step + 1;
                return this.downloadReconciliation(reconciliation, nextStep);
            }
            catch(error) {
                console.log(error);
                return null;
            }
        }
        else {
            return true;
        }
    }
    /**
     * Проверить сверки
     * @static
     * @async
     * @param {Array} reconciliations - сверки
     * @param {Function} actionAfter - событие, сработающее после проверки
     */
    static async checkReconciliations(reconciliations = [], actionAfter = () => {}) {
        const requestPackage = reconciliations.map(reconciliation => this.checkReconciliation(reconciliation, 1, reconciliation));
        const checkData = await Promise.all(requestPackage);
        const checkDataFiltered = checkData.filter(checkDataItem => checkDataItem !== null);
        actionAfter(checkDataFiltered);
    }
    /**
     * Проверить сверку
     * @static
     * @async
     * @param {Object} reconciliation - объект сверки
     * @param {Number} step - номер шага проверки
     * @param {Object} resultRequestOld - аккумулирующий объект результата проверки
     * @returns {Object}
     */
    static async checkReconciliation(reconciliation = {}, step = -1, resultRequestOld = {}) {
        const {fileId = '-1', presetId = '-1', date = '', type = 1, steps = 0} = reconciliation;
        if (step <= steps && step !== -1) {
            const postData = {file_id: fileId, preset_id: presetId, date, step, type, is_loading: false};
            try {
                const res = await ServiceApi.post('reconciliation', '/upload/default/add-to-revise/', postData);
                const {data = {}} = res;
                const {data: dataCheck = {}} = data;
                const resultRequestCollected = this.collectReconciliationCheckData(resultRequestOld, dataCheck);
                const nextStep = step + 1;
                return this.checkReconciliation(reconciliation, nextStep, resultRequestCollected);
            }
            catch(error) {
                console.log(error);
                return null;
            }
        }
        else {
            return resultRequestOld;
        }
    }
    /**
     * Аккумулировать данные проверки сверки
     * @static
     * @param {Object} resultRequestOld - аккумулирующий объект результата проверки
     * @param {Object} resultRequestNew - объект проверки на новом шаге
     * @returns {Object}
     */
    static collectReconciliationCheckData(resultRequestOld, resultRequestNew) {
        const {
            created: createdOld = 0,
            found: foundOld = 0,
            notFound: notFoundOld = 0,
            total: totalOld = 0,
            updated: updatedOld = 0,
            report: reportOld = [],
            canUpload: canUploadOld = true
        } = resultRequestOld;
        const {
            created: createdNew = 0,
            found: foundNew = 0,
            not_found: notFoundNew = 0,
            total: totalNew = 0,
            updated: updatedNew = 0,
            report: reportNew = [],
            can_upload: canUploadNew = true
        } = resultRequestNew;
        const resultRequestCollected = {
            ...resultRequestOld,
            created: parseInt(createdOld) + parseInt(createdNew),
            found: parseInt(foundOld) + parseInt(foundNew),
            notFound: parseInt(notFoundOld) + parseInt(notFoundNew),
            total: parseInt(totalOld) + parseInt(totalNew),
            updated: parseInt(updatedOld) + parseInt(updatedNew),
            report: reportOld.concat(reportNew),
            canUpload: !canUploadNew ? canUploadNew : canUploadOld
        };
        return resultRequestCollected;
    }
    /**
     * Прикрепить массив файлов
     * @static
     * @async
     * @param {Array} files - файлы
     * @param {String} date - дата
     * @param {Function} actionAfter - событие, сработающее после прикрепления
     */
    static async attachFiles(files = [], date = '', actionAfter = () => {}) {
        const requestPackage = files.map(file => this.attachFile(file, date));
        const filesAttached = await Promise.all(requestPackage);
        const filesFiltered = filesAttached.filter(file => file !== null);
        this.getReconciliations(filesFiltered, actionAfter);
    }
    /**
     * Прикрепить файл
     * @static
     * @async
     * @param {File} file - файл
     * @param {String} date - дата
     * @returns {Object}
     */
    static async attachFile(file = {}, date = '') {
        const {name, formData, reqConfog} = this.preprocessedFileData(file);
        try {
            const res = await ServiceApi.post('reconciliation', '/upload/default/upload-file/', formData, reqConfog);
            const {data = {}} = res;
            const {data: dataFile = {}} = data;
            const {fileId = '-1', per_step: perStep = 0, total_rows: totalRows = 0, error = null} = dataFile;
            if (error !== null) {
                alert(`Не удалось прикрепить файл: ${name}`);
                return null;
            }
            else
                return {fileId, fileName: name, perStep, totalRows, date};
        }
        catch(error) {
            console.log(error);
            return null;
        }
    }
    /**
     * Проверить файл
     * @static
     * @async
     * @param {Number} file_id - файл
     * @param {String} date - дата
     * @returns {Object}
     */
    static async checkFile(file_id = {}, date = '') {
        try {
            const res = await ServiceApi.post('reconciliation', '/upload/default/check-file/', {file_id: file_id});
            const {data = {}} = res;
            const {data: dataFile = {}} = data;
            const {fileId = '-1', per_step: perStep = 0, total_rows: totalRows = 0, error = null, name = ''} = dataFile;
            if (error !== null) {
                alert(`Не удалось прикрепить файл: ${name}`);
                return null;
            }
            else
                return {fileId, fileName: name, perStep, totalRows, date};
        }
        catch(error) {
            console.log(error);
            return null;
        }
    }
    /**
     * Получить сверки
     * @static
     * @async
     * @param {Array} files - массив файлов
     * @param {Function} actionAfter - событие, сработающее после получения пресетов
     */
    static async getReconciliations(files = [], actionAfter = () => {}) {
        const requestPackage = files.map(file => this.getPreset(file));
        const reconciliations = await Promise.all(requestPackage);
        const reconciliationsFiltered = reconciliations.filter(reconciliation => reconciliation !== null);
        const reconciliationsAdapted = this.reconciliationsAdapter(reconciliationsFiltered);
        actionAfter(reconciliationsAdapted);
    }
    /**
     * Получить пресет
     * @static
     * @async
     * @param {Object} file - прикрепленный файл
     * @returns {Object}
     */
    static async getPreset(file = {}) {
        const {fileId = '-1'} = file;
        const postData = {file_id: fileId};
        try {
            const res = await ServiceApi.post('reconciliation', '/upload/default/get-preset', postData);
            const {data = {}} = res;
            const {data: dataPreset = {}} = data;
            const {is_found: isFound = false} = dataPreset;
            let {preset = {}} = dataPreset;
            preset = preset === null ? {} : preset;
            const {id: presetId = '-1', name: presetName = ''} = preset;
            const reconciliations = {
                ...file,
                presetId: isFound ? presetId : '-1',
                presetName: isFound ? presetName : ''
            };
            return reconciliations;
        }
        catch(error) {
            console.log(error);
            return null;
        }
    }
    /**
     * Подготовить данные файла перед отправкой
     * @static
     * @param {File} file - файл
     * @returns {Object}
     */
    static preprocessedFileData(file = {}) {
        const {name} = file;
        let formData = new FormData();
        formData.append('file', file);
        const reqConfog = {headers: {'Content-Type': 'multipart/form-data'}};
        return {name, formData, reqConfog};
    }
    /**
     * Адаптер для сверок
     * @param {Array} reconciliations - сверки
     * @returns {Array}
     */
    static reconciliationsAdapter(reconciliations = []) {
        const reconciliationsAdapted = reconciliations.map((reconciliation, index) => {
            const {
                fileId = '-1',
                fileName = '',
                presetId = '-1',
                presetName = '',
                perStep = 0,
                totalRows = 0,
                date = ''
            } = reconciliation;
            const reconciliationAdapted = {
                id: String(index),
                fileId: String(fileId),
                fileName: String(fileName),
                presetId: String(presetId),
                templateName: String(presetName),
                perStep: parseInt(perStep),
                totalRows: parseInt(totalRows),
                steps: Math.ceil(parseInt(totalRows) / parseInt(perStep)),
                date: String(date)
            };
            return reconciliationAdapted;
        });
        return reconciliationsAdapted;
    }
    /**
     * Валидация файлов
     * @param {Array} files - файлы
     * @returns {Array}
     */
    validationFiles(files = []) {
        const filesFiltered = files.filter(file => {
            const {size = 0, name = ''} = file;
            const sizeInMB = (parseFloat(size) / (1024*1024)).toFixed(2);
            if (sizeInMB > this.maxSizeFile)
                alert(`Файл ${name} имеет размер больше ${this.maxSizeFile} мб`);
            return sizeInMB <= this.maxSizeFile;
        });
        return filesFiltered;
    }
}
